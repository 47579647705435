import $ from "jquery";

export class FormController {
    constructor() {

        //disabled button on submit
        $(document).on('click', '.wpcf7-submit', function(e){
            if( $('.ajax-loader').hasClass('is-active') ) {
                e.preventDefault();
                console.log('ajax-load');
                return false;
            }
        });

        const ctaButton = document.querySelector('form .submit-container .submit .wpcf7-submit ');
        var form_being_submitted = false;
        var checkForm = function(e) {
            if(form_being_submitted) {
                ctaButton.disabled = true;
                e.preventDefault();
                return;
            }
            form_being_submitted = true;
        };

        const contactForm = document.getElementById("form-contact");
        if (contactForm){
            contactForm.addEventListener("submit", checkForm, false);
        }

        const candidateForm = document.getElementById("js-application-form");
        if (candidateForm){
            candidateForm.addEventListener("submit", checkForm, false);
        }

        const searchPostForm = document.querySelector('.searchandfilter');
        if ( searchPostForm ) {
            const searchField = document.getElementsByName('ofsearch');
            if (searchField){
                $(searchField).attr('minLength','4');
            }
        }
    }
}