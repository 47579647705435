export class CentersController {
    constructor() {

    this.EventOnbtnDesktop();

    }

    EventOnbtnDesktop() {

        setTimeout(function(){
                let btn = document.querySelector('.map-display');
                if(btn){
                    let map_center = document.querySelector('.container .map-container .mapboxgl-map');
                    btn.onclick = function() {
                        map_center.classList.toggle("active");
                        if (btn.innerHTML === "Afficher la carte") {
                            btn.innerHTML = "Fermer la carte";
                        } else {
                            btn.innerHTML = "Afficher la carte";
                        }
                    };
                }
            },
            700);
    }
}