import $ from 'jquery';
export class ObfusquationController {
    constructor() {
        this.clickOnObfLink();
    }

    clickOnObfLink() {
        $('.lien-obfusque').on('click', function(e) {
            e.preventDefault();
            var link = atob($(this).data('loc'));
            $(location).attr('href',link);

        });
    }
}