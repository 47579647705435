import $ from 'jquery';

$('document').ready(function () {

  const $form = $('#js-application-form');
  if ($form.length === 0) {
    return null;
  }

  const $fieldPosition = $form.find('.js-field-position');
  const $fieldTitle = $form.find('.js-field-title');
  const $fieldOfferNumber = $form.find('.js-field-offer-number');
  const $fieldRegion1 = $form.find('.js-field-region-1');
  const $fieldRegion2 = $form.find('.js-field-region-2');
  const $fieldRegion3 = $form.find('.js-field-region-3');
  const $hiddenPosition = $form.find('.hidden-position');
  const $hiddenRegion1 = $form.find('.hidden-region-1');

  const $fieldTitleContainer = $fieldTitle.closest('.js-field-container');
  const $fieldRegion2Container = $fieldRegion2.closest('.js-field-container');
  const $fieldRegion3Container = $fieldRegion3.closest('.js-field-container');

  const urlParams = new URLSearchParams(window.location.search);
  const offerNumber = urlParams.get('pv-offerNumber');
  const position = urlParams.get('pv-position');
  const region1 = urlParams.get('pv-region');
  const title = urlParams.get('pv-title');

  // load regions
  $.ajax({
    url : pv_api_base_url + "/region",
    type : 'GET',
    dataType : 'json',
    success: function(data) {
      let regionId = "";
      $.each(data, function( k, v ) {
        $fieldRegion1.append(new Option(v.intitule, v.idRegion));
        $fieldRegion2.append(new Option(v.intitule, v.idRegion));
        $fieldRegion3.append(new Option(v.intitule, v.idRegion));
        if(region1 && region1 == v.intitule) {
          regionId = v.idRegion;
        }
      });
      if (offerNumber) {
        $fieldOfferNumber.val(offerNumber);

        if (region1) {
          $fieldRegion1.val(regionId);
          $hiddenRegion1.val($fieldRegion1.children("option:selected").text());
          $fieldRegion2Container.hide();
          $fieldRegion3Container.hide();
        }
      }
    },
  });

  // load functions
  $.ajax({
    url : pv_api_base_url + "/fonction",
    type : 'GET',
    dataType : 'json',
  })
    .done(function(data){
      $.each(data, function( k, v ) {
        $fieldPosition.append(new Option(v.intitule, v.idFonction));
        if(position && position == v.intitule) {
          $fieldPosition.val(v.idFonction);
        }
      });
      $hiddenPosition.val($fieldPosition.children("option:selected").text());
    });

  if ($fieldPosition.children("option:selected").text() !== 'Autre fonction') {
    $fieldTitleContainer.hide();
    $fieldTitle.val($fieldPosition.val());
  }

  if (title) {
    $fieldTitle.val(title);
  }

  $fieldOfferNumber.on('change', function () {
    $fieldRegion2Container.show();
    $fieldRegion3Container.show();
  });

  $fieldRegion1.on('change', function () {
    $hiddenRegion1.val($fieldRegion1.children("option:selected").text());
  });

  $fieldPosition.on('change', function () {
    if ($fieldPosition.children("option:selected").text() === 'Autre fonction') {
      $fieldTitleContainer.show();
      $fieldTitle.val('');
    } else {
      $fieldTitleContainer.hide();
      $fieldTitle.val($fieldPosition.val());
    }
    $hiddenPosition.val($fieldPosition.children("option:selected").text());
  });

  // prevent submit by press "Enter"
  $form.on('keydown', 'input', function (e) {
    return e.key !== "Enter";
  });
});
