export class TarteaucitronController {
    constructor() {
        var tarteaucitron = document.querySelector('#tarteaucitronRoot');
        if (tarteaucitron){
            var tarteaucitronDisclaimerAlert = document.querySelector('#tarteaucitronDisclaimerAlert');
            tarteaucitronDisclaimerAlert.innerText = "Bienvenue sur le site du Groupe Point Vision !\n\nLe Groupe Point Vision et ses partenaires utilisent des cookies ou une technologie équivalente afin d’analyser votre navigation et mesurer l’audience du présent site internet.Ces cookies permettent l’analyse de la fréquentation de notre site par l’enregistrement des informations sur les sources du trafic, les pages consultées, leur heure et date de consultation. Ils contribuent à l’amélioration du présent site.\n\nVous pouvez paramétrer vos choix pour accepter ou non ces cookies en cliquant, soit sur « Accepter et continuer », soit sur « Paramétrer les cookies ».Pour en savoir plus sur les cookies, les données que nous utilisons, les traitements que nous réalisons et les partenaires avec lesquels nous travaillons, vous pouvez consulter nos politiques de gestion des cookies et de confidentialité."
            var tarteaucitronPersonalize = document.querySelector('#tarteaucitronPersonalize');
            tarteaucitronPersonalize.textContent = "✓ Accepter et continuer";
            var tarteaucitronCloseAlert = document.querySelector('#tarteaucitronCloseAlert');
            tarteaucitronCloseAlert.textContent = "Paramétrer les cookies";
        }
    }
}