export class AccordionController {
    constructor() {
        this.clickDisplayContentAccordion();
    }

    clickDisplayContentAccordion() {
        const chaptersTitle = document.querySelectorAll('.chapter-title');
        if (chaptersTitle) {
            chaptersTitle.forEach(function(chapterTitle, chapterContent) {
                chapterTitle.addEventListener('click', function(e) {
                    e.preventDefault();

                    this.parentNode.classList.toggle('on');

                    chaptersTitle.forEach(function(chapterTitle2, chapterContent2) {
                        if ( chapterContent !== chapterContent2 ) {
                            chapterTitle2.parentNode.classList.remove('on');
                        }
                    });
                });
            });
        }
    }
}