import objectFitImages from 'object-fit-images';
import {HomeController} from './controllers/HomeController';
import {NavController} from "./controllers/NavController";
import {ScrollController} from "./controllers/ScrollController";
import {SwiperController} from "./controllers/SwiperController";
import {YoutubeController} from "./controllers/YoutubeController";
import {CookieController} from "./controllers/CookieController";
import {BannerController} from "./controllers/BannerController";
import {FormController} from "./controllers/FormController";
import {CentersController} from "./controllers/CentersController";
import {WizvilleController} from "./controllers/WizvilleController";
import {ObfusquationController} from "./controllers/ObfusquationController";
import {AccordionController} from "./controllers/AccordionController";
import {TarteaucitronController} from "./controllers/TarteaucitronController";

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

import {Router} from './Router';
import {ApplicationForm} from './application-form'

objectFitImages();

/*
 * Matches a body class with a controller. Classes can be passed via a string or an array.
 * The router stops at the first matched route.
 */
const router = new Router([
  {
    classes: 'home',
    controller: HomeController,
  },
]);

document.addEventListener('DOMContentLoaded', () => {

	router.start();
	new NavController();
	new ScrollController();
	new YoutubeController();
	new SwiperController();
	new CookieController();
	new BannerController();
	new FormController();
	new CentersController();
	new WizvilleController();
	new ObfusquationController();
	new AccordionController();
});

/*window.onload = function() {
	setTimeout(loadAfterTime, 1000)
};

function loadAfterTime(){
	new TarteaucitronController();
}*/
