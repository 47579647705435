export class BannerController {
    constructor() {
        this.clickEventOnClose();
    }

    clickEventOnClose() {
        const banner = document.querySelector('.banner');
        const closeButton = document.querySelector('.banner-close');
        if (banner){
            const cookieValue = document.cookie.split('; ').find(row => row.startsWith('bannerClose'));
            console.log(cookieValue);
            if (cookieValue === "bannerClose=true"){
                banner.classList.add('d-none');
            }
            closeButton.addEventListener('click',function () {
                banner.classList.add('d-none');
                sessionStorage.setItem('bannerClose', 'true');
                var d = new Date();
                d.setTime(d.getTime() + 86400);
                var expires = "expires="+d.toUTCString();
                document.cookie = 'bannerClose' + "=" + 'true' + ";" + expires + ";path=/";
            })
        }
    }
}