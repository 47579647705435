import Swiper from "swiper";

export class SwiperController {
    constructor() {
        const swiper = new Swiper('.swiper-container', {
            slidesPerView: 1,
            spaceBetween: 30,
            centeredSlides: false,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoHeight:true,
        });

        var delayTime = document.getElementById('delayTime');

        if (delayTime) {
            var delayTimevalue = delayTime.value;
        }

        const swiperIntroBlock = new Swiper('.swiper-container-intro-block', {
            slidesPerView: 1,
            centeredSlides: true,
            loop: true,
            autoplay: {
                delay: delayTimevalue,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoHeight:true,
        });
    }
}