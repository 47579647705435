export class NavController {
    constructor() {
        this.screenWidth = window.screen.width;

        this.clickEventOnSubMenu();
    }

    clickEventOnSubMenu() {
            const menu                        = document.querySelector('.nav');
            const megaMenuItems               = document.querySelectorAll('.has-mega-menu');
            const megaMenuBackButton          = document.querySelector('.back-megamenu-mobile');
            const megaMenuLinkButton          = document.querySelector('.link-megamenu-mobile');
            const headerButtonContact         = document.querySelector('#inner-center-button-header');
            const headerButtonQuestion        = document.querySelector('#inner-question-button-header');
            const headerButtonRdv             = document.querySelector('#inner-rdv-button-header');
            const headerButtonMeet            = document.querySelector('#inner-meet-button-header');
            const headerFirstbuttonShortText  = document.querySelector('#header-first-button-short-text');
            const headerSecondbuttonShortText = document.querySelector('#header-second-button-short-text');
            const headerMenuMobileButton      = document.querySelector('.menu-button-mobile');
            const banner                      = document.querySelector('.banner');
            const breadcrumbs                 = document.querySelector('#breadcrumbs');
            const primary                     = document.querySelector('#primary');
            const footer                      = document.querySelector('footer');
            if (this.screenWidth < 992) {
                if (menu) {
                    menu.classList.add('d-none');
                    if (headerMenuMobileButton) {
                        headerMenuMobileButton.addEventListener('click', function () {
                            if (this.textContent === "MENU") {
                                this.textContent = "FERMER";
                                menu.classList.remove('d-none');
                                footer.classList.add('d-none');
                                primary.classList.add('d-none');
                                if (banner) {
                                    banner.classList.add('d-none');
                                }
                                if (breadcrumbs) {
                                    breadcrumbs.classList.add('d-none');
                                }
                                if (document.querySelector('header .has-mega-menu.clicked')) {
                                    megaMenuBackButton.classList.add('show');
                                    megaMenuLinkButton.classList.add('show-link');
                                }
                            } else {
                                this.textContent = "MENU";
                                menu.classList.add('d-none');
                                footer.classList.remove('d-none');
                                primary.classList.remove('d-none');
                                if (banner) {
                                    banner.classList.remove('d-none');
                                }
                                if (breadcrumbs) {
                                    breadcrumbs.classList.remove('d-none');
                                }
                                megaMenuBackButton.classList.remove('show');
                                megaMenuLinkButton.classList.remove('show-link');
                            }
                        })
                        Array.from(document.querySelectorAll('ul li a')).forEach(link => {
                            if (link.parentElement.classList.contains('menu-item-has-children')) {
                                link.addEventListener('click', e => {
                                    e.preventDefault();
                                    megaMenuLinkButton.text = link.innerText;
                                    megaMenuLinkButton.href = link.href;
                                    link.parentElement.classList.add('clicked');
                                    megaMenuLinkButton.classList.add('show-link');
                                    megaMenuBackButton.classList.add('show');
                                });
                            }
                        });

                        megaMenuBackButton.addEventListener('click', function () {
                            let clickedTable = document.querySelectorAll('.menu .clicked'),
                                lastChild = clickedTable[clickedTable.length - 1];
                            lastChild.classList.remove('clicked');

                            if (lastChild.parentElement.classList.contains('menu')) {
                                this.classList.remove('show');
                                megaMenuLinkButton.classList.remove('show');
                                megaMenuLinkButton.text = "";
                            } else {
                                megaMenuLinkButton.text = lastChild.parentElement.previousElementSibling.innerText;
                            }
                        });
                        if (headerButtonQuestion) {
                            if (headerSecondbuttonShortText) {
                                headerButtonQuestion.textContent = headerSecondbuttonShortText.value;
                            }
                        }
                        if (headerButtonMeet) {
                            if (headerSecondbuttonShortText) {
                                headerButtonMeet.textContent = headerSecondbuttonShortText.value;
                            }
                        }
                        if (headerButtonContact) {
                            if (headerFirstbuttonShortText) {
                                headerButtonContact.textContent = headerFirstbuttonShortText.value;
                            }
                        }
                        if (headerButtonRdv) {
                            if (headerFirstbuttonShortText) {
                                headerButtonRdv.textContent = headerFirstbuttonShortText.value;
                            }
                        }
                    }
                }
            }
            else {
                if (headerButtonContact){
                    headerButtonContact.textContent = headerButtonContact.getAttribute("value");
                }
                if (headerButtonQuestion){
                    headerButtonQuestion.textContent = headerButtonQuestion.getAttribute("value");
                }
                if (headerButtonRdv){
                    headerButtonRdv.textContent = headerButtonRdv.getAttribute("value");
                }
                if (headerButtonMeet){
                    headerButtonMeet.textContent = headerButtonMeet.getAttribute("value");
                }
                document.addEventListener('click', function (e) {
                    let targetElement = e.target;
                    Array.from(megaMenuItems).forEach(megaMenuItem => {
                       if (targetElement.parentElement === megaMenuItem){
                           if (megaMenuItem.classList.contains('clicked')){
                               megaMenuItem.classList.remove('clicked');
                           }
                           else {
                               if(document.querySelector('header .has-mega-menu.clicked')){
                                   document.querySelector('header .has-mega-menu.clicked').classList.remove('clicked');
                               }
                               megaMenuItem.classList.add('clicked');
                           }
                       }
                       else{
                           if (megaMenuItem.classList.contains('clicked')){
                               megaMenuItem.classList.remove('clicked');
                           }
                       }
                    });
                });
            }
    }
}