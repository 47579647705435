export class YoutubeController {
    constructor() {

        // youtube api
        function getVideoId() {
            if(document.querySelector('#player')){
                return document.querySelector('#player').dataset.id;
            }
            //return 'l-gQLqv9f4o';
        }

        const slides = document.querySelectorAll('.player-carousel');


        function loadPlayer() {

            if (typeof (YT) == 'undefined' || typeof (YT.Player) == 'undefined') {

                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";

                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                window.onYouTubePlayerAPIReady = function () {
                    onYouTubePlayer();
                    onYoutubePlayerCarousel();

                };

            } else {
                onYouTubePlayer();
                onYoutubePlayerCarousel();
            }
        }

        loadPlayer();

        var player;

        function onYouTubePlayer() {
            player = new YT.Player('player', {
                height: '360',
                width: '880',
                videoId: getVideoId(),
                playerVars: {
                    controls: 1,
                    modestbranding: 1,
                    showinfo: 0,
                    rel: 0,
                    showsearch: 0,
                    iv_load_policy: 3
                }
            });
        }

        function onYoutubePlayerCarousel(){
            Array.from(slides).forEach(slide =>{
                var parent = slide.parentElement;
                var playerCarousel = new YT.Player(slide, {
                    height: '100%',
                    width: '100%',
                    videoId: slide.dataset.id,
                    playerVars: {
                        controls: 1,
                        modestbranding: 1,
                        showinfo: 0,
                        rel: 0,
                        showsearch: 0,
                        iv_load_policy: 3
                    }
                });

                // lancé la video du carousel
                var youtubePosterCarousel = parent.querySelector('.youtube-carousel-poster');
                var youtubeButtonCarousel = parent.querySelector('.youtube-carousel-button');
                function playVideoCarousel($element) {
                    playerCarousel.playVideo();
                    $element.querySelector('.youtube-carousel-poster').classList.add('d-none');
                    $element.querySelector('.youtube-carousel-button').classList.add('d-none');
                }
                if (youtubeButtonCarousel){
                    youtubeButtonCarousel.addEventListener('click', function () {
                        playVideoCarousel(youtubeButtonCarousel.parentElement);
                    });
                }
                if (youtubePosterCarousel){
                    youtubePosterCarousel.addEventListener('click', function () {
                        playVideoCarousel(youtubePosterCarousel.parentElement);
                    });
                }
            });
        }

        // lancé la video
        const youtubePoster = document.querySelector('.youtube-slider-poster');
        const youtubeButton = document.querySelector('.youtube-slider-button');

        if (youtubeButton){
            youtubeButton.addEventListener('click', function () {
                youtubePoster.classList.add('d-none');
                youtubeButton.classList.add('d-none');
                player.playVideo();
            })
        }

        //renvoyer sur un chapitre de la video
        const chapters = document.querySelectorAll('.home-block-video-content-list-chapter');

        Array.from(chapters).forEach(chapter => {
            chapter.addEventListener('click', function () {
                if (document.querySelector('.home-block-video-content-list-chapter.clicked')){
                    document.querySelector('.home-block-video-content-list-chapter.clicked').classList.remove('clicked');
                }
                else{
                    youtubePoster.classList.add('d-none');
                    youtubeButton.classList.add('d-none');
                }
                chapter.classList.add('clicked');
                player.seekTo(chapter.querySelector('.home-block-video-content-list-duration').dataset.time);

                player.playVideo();
            });
        });
    }
}
